import Vue from 'vue'

var app_url = '/netcat/modules/default/ajax.php';
var cart_api = '/netcat/modules/netshop/actions/cart.php';
var main_item_class_id = 161;

var app_mini_cart = new Vue({
	delimiters : [ '${', '}' ],
	el : '#mini-cart',
	data : {
		test: 'hi',
		cart_content : [],
		cart_qty : '',
		cart_total_price : '',
		show_details : 0,
	},
	
	computed: {
//	    show_submit_btn: function () {
//	    	if ((this.all_banners_selected == 1)&&(this.all_auto_selected == 1)){
//	    		return 1;
//	    	} else {
//	    		return 0;
//	    	}
//	    },
	},

	beforeCreate : function() {
		self = this;
//		$.ajax({
//			method : "POST",
//			url : app_url,
//			data : {
//				ajax : 'before_edit',
//				message_id : message_id
//			},
//			success : function(msg) {
//				msg_obj = JSON.parse(msg);
//				self.img_list = msg_obj.img_list;
//				self.get_existed_img();
//			}
//		});
		
		
	},
	
	created: function() {
		var self = this;
		self.get_cart();
	},
	
	updated : function() {
		self=this;

	},
	mounted: function(){
		self=this;
		
		
	},
	watch : {
		cart_content : function(){
			self=this;
		}
	},
	methods : {
		get_cart_items_data : function(){
			$.ajax({
				method : "POST",
				url : app_url,
				data : {
					ajax : 'get_cart_items_data',
				},
				success : function(msg){
					Object.keys(msg.data).forEach(function (prop) {  
					    self.cart_content[prop]['bearing_params'] = msg.data[prop];
					});
					self.show_details = 1;
				}
			});
		},
		
		delete_item_from_cart : function(item_id, component_id, event){
			if (event) event.preventDefault();
			var self = this;
			self.show_details = 0;
			
			(!component_id)||(component_id=='') ? component_id = main_item_class_id : null;
			
			$.ajax({
				method : "POST",
				url : cart_api,
				data : {
					cart_mode : '',
					items : [component_id + ':' + item_id],
					qty : 0,
				},
				success : function(msg){
//					location.reload();
					 self.get_cart();
				}
			});
			
			
		},
		
//		submit_payment : function(event){
//			event.preventDefault();
//			self = this;
//			
//			$.ajax({
//				method : "POST",
//				url : app_url,
//				data : {
//					ajax : 'add_new_order'
//				},
//				success : function(msg) {
//					if (msg.status=='success'){
//						window.location = msg.payment_link;
//					}else if(msg.status=='error'){
//					}
//					
//				}
//			});
//		},
		
		get_cart : function (component_id){
			var self = this;
			(!component_id)||(component_id=='') ? component_id = main_item_class_id : null;
			
			$.ajax({
				method : "POST",
				url : cart_api,
				data : {
					json : 1,
					cart_mode : 'add',
					items : [component_id + ':0'],
					qty : 0,
				},
				success : function(msg){
					self.get_cart_items_data();
					var msg_obj = JSON.parse(msg);
					self.cart_content = msg_obj.Items;
					self.cart_total_price = msg_obj.TotalItemPrice;
					self.cart_qty = msg_obj.TotalCount;
				}
			});
		},
		
		clear_cart : function (){
			var self = this;
			self.show_details = 0;
			$.ajax({
				method : "POST",
				url : cart_api,
				data : {
					cart_clear : true,
				},
				success : function(msg){
					self.get_cart();
				}
			});
		},
		
		add_item_to_cart : function (item_id, qty, component_id, replace, next_page){
			var self = this;
			self.show_details = 0;
			(!component_id)||(component_id=='') ? component_id = main_item_class_id : null;
			
			$.ajax({
				method : "POST",
				url : cart_api,
				data : {
					cart_mode : replace ? '' : 'add',
					items : [component_id + ':' + item_id],
					qty : qty,
				},
				success : function(msg){
					if (next_page) {
						console.log('go to '+next_page);
						window.location.href = next_page;
					}
					self.get_cart();
					replace ? null : console.log('Товар добавлен в корзину');
				}
			});
		},
		
		remove_item : function (item_id, component_id, event){
			if (event) event.preventDefault();
			var self = this;
			self.show_details = 0;
			(!component_id)||(component_id=='') ? component_id = main_item_class_id : null;
			
			$.ajax({
				method : "POST",
				url : cart_api,
				data : {
					cart_mode : 'add',
					items : [component_id + ':' + item_id],
					qty : 0,
				},
				success : function(msg){
					self.get_cart();
				}
			});
		},
	},
});

window.app_mini_cart = app_mini_cart;
window.app_url = app_url;